<script>
export default {
  name: 'ResultFilter',
  props: {
    minPrice: {
      type: Number,
      default: 0,
    },
    maxPrice: {
      type: Number,
      default: 10000,
    },
    minPriceRange: {
      type: Number,
      default: 0,
    },
    maxPriceRange: {
      type: Number,
      default: 10000,
    },
    filterStep: {
      type: Number,
      default: 500,
    },
    category: {
      type: String,
      default: '',
    },
    // 基礎化粧品、スペシャルケア
    isSkinLotionSelected: {
      type: Boolean,
      default: true,
    },
    isMilkCreamSelected: {
      type: Boolean,
      default: false,
    },
    isAllInOneSelected: {
      type: Boolean,
      default: false,
    },
    isCeramideSelected: {
      type: Boolean,
      default: false,
    },
    isVitaminCSelected: {
      type: Boolean,
      default: false,
    },
    isRetinolSelected: {
      type: Boolean,
      default: false,
    },
    isNiacinamideSelected: {
      type: Boolean,
      default: false,
    },
    isTranexamicAcidSelected: {
      type: Boolean,
      default: false,
    },
    // 洗顔
    isPoresSelected: {
      type: Boolean,
      default: false,
    },
    isRoughSkinSelected: {
      type: Boolean,
      default: false,
    },
    isOilySkinSelected: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:minPrice',
    'update:maxPrice',
    'update:minPriceRange',
    'update:maxPriceRange',
    'update:filterStep',
    'update:isSkinLotionSelected',
    'update:isMilkCreamSelected',
    'update:isAllInOneSelected',
    'update:isCeramideSelected',
    'update:isVitaminCSelected',
    'update:isRetinolSelected',
    'update:isNiacinamideSelected',
    'update:isTranexamicAcidSelected',
    'update:isPoresSelected',
    'update:isRoughSkinSelected',
    'update:isOilySkinSelected',
  ],
  data() {
    return {
      // priceはinterface、valueは操作される数値
      minValue: 0,
      maxValue: 10000,
    };
  },
  computed: {
    marginLeft() {
      return (100 * Math.min(this.minValue, this.maxValue)) / this.maxPriceRange + '%';
    },
    coloredWidth() {
      return (100 * Math.abs(this.maxValue - this.minValue)) / this.maxPriceRange + '%';
    },
    /* eslint-disable vue/return-in-computed-property */
    displayMinPrice() {
      if (this.$i18n.locale == 'ja') {
        return this.minValue == this.minPriceRange
          ? '下限なし'
          : this.minValue.toLocaleString() + '円';
      } else if (this.$i18n.locale == 'en') {
        return this.minValue == this.minPriceRange
          ? 'No lower limit'
          : this.minValue.toLocaleString() + 'yen';
      } else if (this.$i18n.locale == 'zh') {
        return this.minValue == this.minPriceRange
          ? '没有下限'
          : this.minValue.toLocaleString() + '日元';
      }
    },
    displayMaxPrice() {
      if (this.$i18n.locale == 'ja') {
        return this.maxValue == this.maxPriceRange
          ? '上限なし'
          : this.maxValue.toLocaleString() + '円';
      } else if (this.$i18n.locale == 'en') {
        return this.maxValue == this.maxPriceRange
          ? 'No upper limit'
          : this.maxValue.toLocaleString() + 'yen';
      } else if (this.$i18n.locale == 'zh') {
        return this.maxValue == this.maxPriceRange
          ? '没有上限'
          : this.maxValue.toLocaleString() + '日元';
      }
    },
    /* eslint-enable vue/return-in-computed-property */
  },
  watch: {
    minPrice: function() {
      this.minValue = this.minPrice;
      this.maxValue = this.maxPrice;
    },
    maxPrice: function() {
      this.minValue = this.minPrice;
      this.maxValue = this.maxPrice;
    },
  },
  mounted() {
    // スペシャルケアのカテゴリーが選択された状態で更新された場合の処理
    if (this.category == 'スペシャルケア') {
      this.minValue = 0;
      this.maxValue = 20000;
      this.$emit('update:filterStep', 1000);
      this.$emit('update:minPriceRange', 0);
      this.$emit('update:maxPriceRange', 20000);
    } else {
      this.minValue = this.minPrice;
      this.maxValue = this.maxPrice;
    }
  },
  methods: {
    update() {
      const largerValue = Math.max(this.minValue, this.maxValue);
      const smallerValue = Math.min(this.minValue, this.maxValue);
      this.minValue = smallerValue;
      this.maxValue = largerValue;
      this.$emit('update:minPrice', this.minValue);
      this.$emit('update:maxPrice', this.maxValue);
    },
    // 基礎化粧品、スペシャルケア
    // カテゴリー
    changeSkinLotion() {
      this.$emit('update:isSkinLotionSelected', !this.isSkinLotionSelected);
      this.$emit('update:isMilkCreamSelected', false);
      this.$emit('update:isAllInOneSelected', false);
    },
    changeMilkCream() {
      this.$emit('update:isSkinLotionSelected', false);
      this.$emit('update:isMilkCreamSelected', !this.isMilkCreamSelected);
      this.$emit('update:isAllInOneSelected', false);
    },
    changeAllInOne() {
      this.$emit('update:isSkinLotionSelected', false);
      this.$emit('update:isMilkCreamSelected', false);
      this.$emit('update:isAllInOneSelected', !this.isAllInOneSelected);
    },
    // 成分
    changeCeramide() {
      this.$emit('update:isCeramideSelected', !this.isCeramideSelected);
      this.$emit('update:isVitaminCSelected', false);
      this.$emit('update:isRetinolSelected', false);
      this.$emit('update:isNiacinamideSelected', false);
      this.$emit('update:isTranexamicAcidSelected', false);
    },
    changeVitaminC() {
      this.$emit('update:isCeramideSelected', false);
      this.$emit('update:isVitaminCSelected', !this.isVitaminCSelected);
      this.$emit('update:isRetinolSelected', false);
      this.$emit('update:isNiacinamideSelected', false);
      this.$emit('update:isTranexamicAcidSelected', false);
    },
    changeRetinol() {
      this.$emit('update:isCeramideSelected', false);
      this.$emit('update:isVitaminCSelected', false);
      this.$emit('update:isRetinolSelected', !this.isRetinolSelected);
      this.$emit('update:isNiacinamideSelected', false);
      this.$emit('update:isTranexamicAcidSelected', false);
    },
    changeNiacinamide() {
      this.$emit('update:isCeramideSelected', false);
      this.$emit('update:isVitaminCSelected', false);
      this.$emit('update:isRetinolSelected', false);
      this.$emit('update:isNiacinamideSelected', !this.isNiacinamideSelected);
      this.$emit('update:isTranexamicAcidSelected', false);
    },
    changeTranexamicAcid() {
      this.$emit('update:isCeramideSelected', false);
      this.$emit('update:isVitaminCSelected', false);
      this.$emit('update:isRetinolSelected', false);
      this.$emit('update:isNiacinamideSelected', false);
      this.$emit('update:isTranexamicAcidSelected', !this.isTranexamicAcidSelected);
    },
    // 洗顔
    changePores() {
      this.$emit('update:isPoresSelected', !this.isPoresSelected);
      this.$emit('update:isRoughSkinSelected', false);
      this.$emit('update:isOilySkinSelected', false);
    },
    changeRoughSkin() {
      this.$emit('update:isPoresSelected', false);
      this.$emit('update:isRoughSkinSelected', !this.isRoughSkinSelected);
      this.$emit('update:isOilySkinSelected', false);
    },
    changeOilySkin() {
      this.$emit('update:isPoresSelected', false);
      this.$emit('update:isRoughSkinSelected', false);
      this.$emit('update:isOilySkinSelected', !this.isOilySkinSelected);
    },
  },
};
</script>

<template>
  <div class="filter__wrapper">
    <div class="filter__contents">
      <div class="filter__contents--price">
        <h4 class="filter__contents--price__title">
          {{ $t('resultFilterPrice') }}
        </h4>
        <div class="filter__contents--price__range">
          <p>{{ displayMinPrice }}&nbsp;~&nbsp;{{ displayMaxPrice }}</p>
        </div>
      </div>
      <div class="filter__contents--price__slider">
        <div class="filter__contents--price__lane" />
        <div class="filter__contents--price__input--wrapper">
          <input
            v-model="minValue"
            type="range"
            :min="minPriceRange"
            :max="maxPriceRange"
            :step="filterStep"
            class="filter__contents--price__input"
            @change="update"
          >
          <input
            v-model="maxValue"
            type="range"
            :min="minPriceRange"
            :max="maxPriceRange"
            :step="filterStep"
            class="filter__contents--price__input"
            @change="update"
          >
        </div>
      </div>
    </div>
    <!-- 基礎化粧品、スペシャルケア -->
    <div
      v-if="
        category=='基礎化粧品' || category=='スペシャルケア'
      "
      class="filter__contents--cateogry"
    >
      <!-- 基礎化粧品のみ -->
      <div v-if="category=='基礎化粧品'">
        <h4 class="filter__contents--cateogry__title">
          {{ $t('resultFilterCategoryDetails') }}
        </h4>
        <div class="filter__contents--category__check">
          <div class="filter__contents--category__display">
            <label for="skin_lotion">
              <input
                id="skin_lotion"
                type="checkbox"
                name="skin_lotion"
                :checked="isSkinLotionSelected"
                @change="changeSkinLotion"
              ><span>{{ $t('categorySkinLotion') }}</span>
            </label>
          </div>
          <div class="filter__contents--category__display">
            <label for="milk_cream">
              <input
                id="milk_cream"
                type="checkbox"
                name="milk_cream"
                :checked="isMilkCreamSelected"
                @change="changeMilkCream"
              ><span>{{ $t('categoryMilkyLotionCream') }}</span>
            </label>
          </div>
          <div class="filter__contents--category__display">
            <label for="all_in_one">
              <input
                id="all_in_one"
                type="checkbox"
                name="all_in_one"
                :checked="isAllInOneSelected"
                @change="changeAllInOne"
              ><span>{{ $t('categoryAllInOne') }}</span>
            </label>
          </div>
        </div>
      </div>
      <h4 class="filter__contents--cateogry__title">
        {{ $t('resultFilterNeedsIngredient') }}
      </h4>
      <div class="filter_ingredient_container">
        <div class="filter_ingredient_firstLine">
          <div class="filter_ingredient">
            <label for="ceramide">
              <input
                id="ceramide"
                type="checkbox"
                name="ceramide"
                :checked="isCeramideSelected"
                @change="changeCeramide"
              ><span>{{ $t('ingredientCeramide') }}</span>
            </label>
          </div>
          <div class="filter_ingredient">
            <label for="vitamin_c">
              <input
                id="vitamin_c"
                type="checkbox"
                name="vitamin_c"
                :checked="isVitaminCSelected"
                @change="changeVitaminC"
              ><span>{{ $t('ingredientVitaminC') }}</span>
            </label>
          </div>
          <div class="filter_ingredient">
            <label for="retinol">
              <input
                id="retinol"
                type="checkbox"
                name="retinol"
                :checked="isRetinolSelected"
                @change="changeRetinol"
              ><span>{{ $t('ingredientRetinol') }}</span>
            </label>
          </div>
        </div>
        <div class="filter_ingredient_secondLine">
          <div class="filter_ingredient">
            <label for="niacinamide">
              <input
                id="niacinamide"
                type="checkbox"
                name="niacinamide"
                :checked="isNiacinamideSelected"
                @change="changeNiacinamide"
              ><span style="margin-right:20px;">{{ $t('ingredientNiacinamide') }}</span>
            </label>
          </div>
          <div class="filter_ingredient">
            <label for="tranexamic_acid">
              <input
                id="tranexamic_acid"
                type="checkbox"
                name="tranexamic_acid"
                :checked="isTranexamicAcidSelected"
                @change="changeTranexamicAcid"
              ><span>{{ $t('ingredientTranexamicAcid') }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- 洗顔 -->
    <div
      v-if="category=='洗顔'"
      class="filter__contents--cateogry"
    >
      <h4 class="filter__contents--cateogry__title">
        {{ $t('resultFilterProblemsBeSolved') }}
      </h4>
      <div class="filter_ingredient_container">
        <div class="filter_ingredient_firstLine">
          <div class="filter_ingredient">
            <label for="pores">
              <input
                id="pores"
                type="checkbox"
                name="pores"
                :checked="isPoresSelected"
                @change="changePores"
              ><span>{{ $t('problemPores') }}</span>
            </label>
          </div>
          <div class="filter_ingredient">
            <label for="rough_skin">
              <input
                id="rough_skin"
                type="checkbox"
                name="rough_skin"
                :checked="isRoughSkinSelected"
                @change="changeRoughSkin"
              ><span>{{ $t('problemRoughSkin') }}</span>
            </label>
          </div>
          <div class="filter_ingredient">
            <label for="oily_skin">
              <input
                id="oily_skin"
                type="checkbox"
                name="oily_skin"
                :checked="isOilySkinSelected"
                @change="changeOilySkin"
              ><span>{{ $t('problemOilySkin') }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* タイトル部分のフィルター */
.filter__wrapper {
  padding-bottom: 10px;
}
.filter__title {
  display: flex;
  padding: 20px 0 16px 0;
}
.filter__title--design {
  width: 6px;
  height: 24px;
  background-color: #f29f8f;
}
.filter__title--word {
  font-size: 20px;
  padding-left: 4px;
}
/* 以下カテゴリーフィルター */
.filter__contents--cateogry__title {
  font-size: 16px;
  padding: 10px 0 15px;
}
.filter__contents--category__check{
  display: flex;
  justify-content: space-between;
}
.filter_ingredient_firstLine{
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
.filter_ingredient_secondLine{
  display: flex;
  margin-top: 3px;
}
.filter_ingredient_container{
  flex-wrap: wrap;
}
.filter__contents--category__display, .filter_ingredient {
  display: block;
  padding-bottom: 15px;
}
.filter__contents--category__display label, .filter_ingredient label{
  padding-left: 4px;
  font-size: 14px;
}
.filter__contents--category__display input, .filter_ingredient input{
  display: none;
}
.filter__contents--category__display span, .filter_ingredient span{
  color: #777;
  font-size: 14px;
  border: 1px solid #F29F8F;
  border-radius: 20px;
  padding: 5px 12px;
}
label input:checked + span{
  color: #FFF;
  background: #F29F8F;
  border: 1px solid #F29F8F;
}
.filter__contents {
  padding-bottom: 20px;
}
.filter__contents--price {
  padding-bottom: 15px;
}
.filter__contents--price__title {
  font-size: 16px;
  padding: 0 20px 0 0;
  display: inline-block;
}
.filter__contents--price__range {
  font-size: 14px;
  display: inline-block;
  float: right;
}
.filter__contents--price__slider {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 10px;
}
.filter__contents--price__lane {
  width: 100%;
  height: 5px;
  background-color: #ddd;
  margin: auto;
  border-radius: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
}
.filter__contents--price__lane::after {
  content: "";
  margin-left: v-bind(marginLeft);
  width: v-bind(coloredWidth);
  height: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #fadad2;
}
.filter__contents--price__input__wrapper {
  width: 100%;
}
.filter__contents--price__input {
  width: 1.2em;
  height: 1.2em;
  background-color: #f29f8f;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
}
input[type="range"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 100%;
  position: absolute;
  background-color: transparent;
  margin: auto;
  top: 0;
  bottom: 0;
  pointer-events: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 5px;
}
input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: 5px;
}
input[type="range"]::-ms-track {
  appearance: none;
  height: 5px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.5em;
  height: 1.5em;
  background-color: #f29f8f;
  cursor: pointer;
  border-radius: 10px;
  pointer-events: auto;
  margin-top: -8px;
}
input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  width: 1.5em;
  height: 1.5em;
  background-color: #f29f8f;
  cursor: pointer;
  border-radius: 50%;
  pointer-events: auto;
  margin-top: -8px;
}
input[type="range"]::-ms-thumb {
  appearance: none;
  width: 1.5em;
  height: 1.5em;
  background-color: #f29f8f;
  cursor: pointer;
  border-radius: 50%;
  pointer-events: auto;
  margin-top: -8px;
}
</style>
