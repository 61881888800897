<script>
import KazunosukeItemDetail from "@/components/KazunosukeItemDetail.vue"

export default {
  name: 'ItemList',
  components: {
    KazunosukeItemDetail
  },
  props: {
    category: {
      type: String,
    },
    items: {
      type: Object,
    }
  },
  data(){
    return {
      genre: "skin-care",
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      selectedItemId: 0,
      itemIds: [],
      displayItemLimit: 5
    }
  },
  computed:{
    sliecedItems(){
      return this.items.slice(0, this.displayItemLimit+1)
    }
  },
  updated(){
    this.itemIds = this.items.map(x => x.item_id)
  },
  methods: {
    showMore(){
      this.displayItemLimit += 5
    },
    selectItem(item_id){
      if(this.selectedItemId == item_id){
        this.selectedItemId =0
        return
      }else{
        console.log(document.getElementsByClassName("suggested_item__component"))
        if(this.itemIds.indexOf(this.selectedItemId)!=-1&&this.selectedItemId!=0 && this.itemIds.indexOf(this.selectedItemId) < this.itemIds.indexOf(item_id)){
          const currentVisibleDetail = document.getElementsByClassName("suggested_item__component")[0]
          const currentVisibleDetailHeight = currentVisibleDetail.clientHeight
          window.scrollBy(0, -currentVisibleDetailHeight)
          this.selectedItemId = item_id
        }else{
          this.selectedItemId = item_id
        }
        this.$gtag.event('view_item', this.convertItem2GA4(item_id))
        return
      }
    },
    convertItem2GA4(item_id) {
      const item = this.items.find( item_ => item_.item_id==item_id)
      return {
        currency: "JPY",
        value:
          item.affiliate_links.amazon != "" || item.affiliate_links != ""
            ? item.official_price
            : 0,
        items: [
          {
            item_id: item.item_id,
            item_name: item.item_name,
            item_brand: item.brand_name,
            item_category: this.genre,
            item_category2: this.cagtegory,
            affiliation: "かずのすけ",
          }
        ]
      }
    },
  }
}
</script>

<template>
  <div class="suggested_item_list">
    <div
      v-if="items.length==0"
      class="no_suggested_item"
    >
      <p>
        {{ $t('kazunosukeItemResultTitle') }}
      </p>
    </div>
    <div
      v-for="(item,index) in sliecedItems"
      :key="item.item_id"
      class="suggested_item_card_container"
    >
      <div
        class="item-card"
        @click="selectItem(item.item_id)"
      >
        <img
          :src="
            ROOT_IMAGE_URL
              + '/'
              + genre
              + '/'
              + item.item_id
              + '.jpg'
          "
          class="item_img"
          :class="[index==displayItemLimit ? 'blurred-item': '']" 
        >
        <div class="item_brief_container" :class="[index==displayItemLimit ? 'blurred-item': '']" >
          <div class="item_brief">
            <p class="item_brief_brandName">
              {{ item.brand_name }}
            </p>
            <p class="item_brief_itemName">
              {{ item.item_name }}
            </p>
            <div>
              <div class="item_brief_priceArea_container">
                <div 
                  v-if="item.official_price > 0"
                  class="item_brief_priceArea"
                >
                  <span>
                    {{ $t('itemRetailPrice') }}:&nbsp;¥{{ item.official_price.toLocaleString() }}
                  </span>
                </div>
                <div
                  v-else
                  class="item_brief_priceArea"
                >
                  <span>
                    {{ $t('itemRetailPrice') }}:&nbsp;-
                  </span>
                </div>
              </div>
              <img
                v-if="selectedItemId==item.item_id" 
                src="@/assets/logos/up_arrow.svg"
                class="select-arrow"
              >
              <img
                v-else
                src="@/assets/logos/down_arrow.svg"
                class="select-arrow"
              >
            </div>
          </div>
        </div>
        <div class="show-more" v-if="index==displayItemLimit">
          <button
            class="show-more-button"
            @click.stop="showMore"
          >
            {{ $t('kazunosukeItemsShowMore') }}
          </button>
        </div>
        <div :class="{selected: selectedItemId==item.item_id}" />
      </div>
      <kazunosuke-item-detail
        v-if="selectedItemId==item.item_id"
        :category="category"
        :genre="genre"
        :item="item"
        :official_price="item.official_price"
      />
    </div>
  </div>
</template>

<style scoped>
.suggested_item_list{
  background-color: #fff;
}
.no_suggested_item p {
  padding: 20px;
  margin-bottom: 10px;
}
.item-card {
  width: 100%;
  display: flex;
  position: relative;
  box-shadow: 1px 2px 6px -6px rgb(0 0 0 / 60%);
}
.item-card:last-of-type{
  margin-bottom: 5px;
}
.item_img {
  width: 30%;
}
.item_brief_container{
  width: 70%;
  padding-left: 10px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.item_brief{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item_brief_brandName{
  font-size: 14px;
  padding-bottom: 5px;
}
.item_brief_itemName{
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 7.5px;
}
.item_brief_priceArea_container{
  font-size: 14px;
  display: inline-block;
  /* color: #F29F8F; */
}
.item_brief_priceArea{
  display: inline-block;
  padding-right: 15px;
}
.item_brief_priceArea img{
  width: 14px;
}
.item_brief_otherConciergeIcon_container{
  display: inline-block;
}
.item_brief_otherConciergeIcon{
  display: inline-block;
}
.item_brief_otherConciergeIcon_container img{
  width: 25px;
  border: solid 1px #CCC;
  border-radius: 50%;
  margin-right: 3px;
}
.select-arrow {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  right: 10px;
}
.show-more {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 10% 10%;
}
.show-more-button {
  width: 100%;
  height: 100%;
  background-color: #fadad2;
  text-align: center;
}
.blurred-item {
  filter: blur(5px);
}
</style>