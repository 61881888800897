<script>
import axios from "axios";

export default {
  name: "ItemDetail",
  props: {
    category: {
      type: String,
    },
    item: {
      type: Object,
    },
    genre: {
      type: String,
    },
    official_price: {
      type: Number,
    },
  },
  data() {
    return {
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      isCommentOpen: false,
      shortCommentHeight: 0,
      fullCommentHeight: 0,
      // モーダル部分
      isOpen: false,
      yahooPrice: 0,
      atcosmePrice: 0,
      rakutenPrice: 0,
      price_data: {},
      splited_data: '',
      youtube_id: '',
      start_second: '',
      url: '',
    };
  },
  computed: {
    genreName() {
      return "スキンケア";
    },
    hasYoutube() {
      return this.item.comment.youtube.length > 0;
    },
    youtubeUrl() {
      return this.item.comment.youtube[0];
    },
    hasBlog() {
      return this.item.comment.blog.length > 0;
    },
    blogUrl() {
      return this.item.comment.blog[0];
    },
  },
  mounted() {
    this.setPriceByItemId(this.item.item_id);
  },
  methods: {
    checkYahooURL(affiliateUrl) {
      return "yahoo" in affiliateUrl && affiliateUrl.yahoo !== "";
    },
    checkAtcosmeURL(affiliateUrl) {
      return "atcosme" in affiliateUrl && affiliateUrl.atcosme !== "";
    },
    checkRakutenURL(affiliateUrl) {
      return "rakuten" in affiliateUrl && affiliateUrl.rakuten !== "";
    },
    commentOpen() {
      const shortCommentElm =
        document.getElementsByClassName("shortened_comment")[0];
      this.shortCommentHeight = shortCommentElm.clientHeight;
      this.isCommentOpen = true;
    },
    commentClose() {
      const fullCommentElm = document.getElementsByClassName("full_comment")[0];
      this.fullCommentHeight = fullCommentElm.clientHeight;
      this.isCommentOpen = false;
      window.scrollBy(0, this.shortCommentHeight - this.fullCommentHeight);
    },
    isAffiliateUrlBlank(affiliateUrls) {
      if (
        (!("yahoo" in affiliateUrls) || affiliateUrls.yahoo === "") &&
        (!("atcosme" in affiliateUrls) || affiliateUrls.atcosme === "") &&
        (!("rakuten" in affiliateUrls) || affiliateUrls.rakuten === "")
      ) {
        return true;
      } else {
        return false;
      }
    },
    setPriceByItemId(itemId) {
      axios
        .post("https://api.dev.yuni-cosme.com/v1/price/", {
          item_id: itemId,
        })
        .then((res) => {
          (this.yahooPrice = "yahoo" in res.data ? res.data.yahoo.price : 0),
          (this.atcosmePrice = "atcosme" in res.data ? res.data.atcosme.price : 0),
          (this.rakutenPrice = "rakuten" in res.data ? res.data.rakuten.price : 0);
        })
        .catch((err) => {
          console.log("ItemDetail", err.code, err.message);
        });
    },
    modalClose(modalState) {
      this.isOpen = modalState;
    },
  },
};
</script>

<template>
  <section class="suggested_item__component">
    <div v-if="genre === 'skin-care'">
      <div class="suggested_item_info__component">
        <div class="item_img_container">
          <img
            class="item_img"
            :src="ROOT_IMAGE_URL + '/' + genre + '/' + item.item_id + '.jpg'"
          >
        </div>
        <div class="item_brand">
          {{ item.brand_name }}
        </div>
        <h2 class="item_name">
          {{ item.item_name }}
        </h2>
        <div
          v-if="!isAffiliateUrlBlank(item.affiliate_links)"
          class="item_purchase"
        >
          <div v-if="checkRakutenURL(item.affiliate_links)" class="rakuten">
            <img src="@/assets/logos/rakuten_logo.svg">
            <div v-if="rakutenPrice === 0">
              <span>&nbsp;-&nbsp;</span>
            </div>
            <div v-else>
              <span>&nbsp;¥{{ rakutenPrice.toLocaleString() }}&nbsp;</span>
            </div>
            <a
              class="purchase_btn"
              :href="`${item.affiliate_links.rakuten}`"
              rel="nofollow"
            >
              {{ $t('itemsLowestPrice') }}
            </a>
          </div>
          <div v-if="checkAtcosmeURL(item.affiliate_links)" class="atcosme">
            <img src="@/assets/logos/atcosme.svg">
            <div v-if="atcosmePrice === 0">
              <span>&nbsp;-&nbsp;</span>
            </div>
            <div v-else>
              <span>&nbsp;¥{{ atcosmePrice.toLocaleString() }}&nbsp;</span>
            </div>
            <a
              class="purchase_btn"
              :href="`${item.affiliate_links.atcosme}`"
              rel="nofollow"
            >
              {{ $t('itemsLowestPrice') }}
            </a>
          </div>
          <div v-if="checkYahooURL(item.affiliate_links)" class="yahoo">
            <img src="@/assets/logos/yahoo.svg">
            <div v-if="yahooPrice === 0">
              <span>&nbsp;-&nbsp;</span>
            </div>
            <div v-else>
              <span>&nbsp;¥{{ yahooPrice.toLocaleString() }}&nbsp;</span>
            </div>
            <a
              class="purchase_btn"
              :href="`${item.affiliate_links.yahoo}`"
              rel="nofollow"
            >
              {{ $t('itemsLowestPrice') }}
            </a>
          </div>
          <span class="item_price_attention">
            {{ $t('itemsAttention') }}
          </span>
        </div>
        <div
          v-if="isAffiliateUrlBlank(item.affiliate_links)"
          class="item_officialPage_btn"
        >
          <a :href="`${item.official_url}`">{{ $t('itemsOfficialUrl') }}</a>
        </div>
        <div class="item_additionalInfo_container">
          <div class="item_additionalInfo">
            <div v-if="item.comment" class="item_comment_container">
              <div class="item_comment_title">
                <p>{{ $t('kazunosukeItemExplanation') }}</p>
              </div>
              <div class="item_comment">
                <div
                  v-if="hasYoutube"
                  class="youtube_description"
                >
                  <iframe
                    width="100%"
                    height="100%"
                    :src="youtubeUrl"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  />
                </div>
                <div 
                  v-if="hasBlog" 
                  class="blog_description"
                >
                  <p>
                    {{ $t('kazunosukeItemBlog') }}
                    <a :href="blogUrl">{{ $t('kazunosukeItemBlogHere') }}</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="item_detailInfo_container">
              <h4>{{ $t('itemDetails') }}</h4>
              <div class="item_detailInfo">
                <ul>
                  <li>
                    <p>{{ $t('itemCategory') }}</p>
                    <span>
                      {{ category }}
                    </span>
                  </li>
                  <li>
                    <p>{{ $t('itemBrand') }}</p>
                    <span>
                      {{ item.brand_name }}
                    </span>
                  </li>
                  <li>
                    <p>{{ $t('itemName') }}</p>
                    <span>
                      {{ item.item_name }}
                    </span>
                  </li>
                  <li>
                    <p>{{ $t('itemRetailPrice') }}</p>
                    <span v-if="official_price > 0">
                      ¥{{ official_price.toLocaleString() }}
                    </span>
                    <span v-else> - </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.suggested_item__component {
  padding: 0 40px;
  background-color: #fff;
}
.suggested_item_info__component {
  padding-bottom: 30px;
}
.item_category {
  display: inline-block;
}
.item_img {
  width: 100%;
  object-fit: cover;
  display: block;
}
.item_brand {
  font-size: 14px;
  padding-bottom: 5px;
}
.item_name {
  font-size: 20px;
  padding-bottom: 12px;
  font-weight: bold;
}
.item_purchase {
  padding-bottom: 43px;
}
.atcosme,
.yahoo,
.rakuten {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  align-self: center;
}
.atcosme img,
.yahoo img,
.rakuten img {
  width: 24px;
}
.atcosme span,
.yahoo span,
.rakuten span {
  font-size: 18px;
  font-weight: bold;
  color: #f29f8f;
  display: inline-block;
  text-align: right;
  width: 80px;
  margin-right: 10px;
}
.item_price {
  font-family: "Brandon Grotesque";
  font-size: 24px;
}
.purchase_btn {
  font-size: 12px;
  font-weight: bold;
  padding: 8px 12px;
  color: #fff;
  background-color: #f29f8f;
  display: inline-block;
}
.item_officialPage_btn {
  text-align: center;
  padding-bottom: 45px;
}
.item_officialPage_btn a {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 18px;
  color: #fff;
  background-color: #f29f8f;
  display: inline-block;
}
.item_price_attention {
  display: block;
  padding-top: 3px;
  font-size: 12px;
}
/* メイク動画・メイク道具・商品詳細 */
.item_additionalInfo_container {
  background-color: #f5eae7;
  padding: 0 20px;
}
.item_additionalInfo {
  padding: 30px 0;
}
.concierges_info_container {
  padding-top: 50px;
}
.concierges_info_container p {
  font-size: 14px;
  color: #777777;
  padding-bottom: 20px;
}
.item_detailInfo_container h4 {
  font-size: 14px;
  color: #777777;
  display: inline-block;
  z-index: 10;
  /* box-shadow: 10px 10px #FADAD2; */
}
.item_detailInfo_container {
  padding-top: 30px;
}
.item_detailInfo {
  color: #777777;
  font-size: 12px;
  padding-top: 20px;
}
.item_detailInfo li {
  display: flex;
  padding-bottom: 14px;
}
.item_detailInfo p {
  display: inline-block;
  width: 30%;
}
.item_detailInfo span {
  padding-left: 20px;
  width: 70%;
}
/* モーダル部分 */
.modal_item_img {
  width: 100%;
  object-fit: cover;
  padding: 25px 0;
}
.grey_screen {
  position: relative;
  pointer-events: none;
}
.grey_screen::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  right: 0px;
  pointer-events: none;
}
.button_disabled {
  pointer-events: none;
}
.item_comment_title {
  text-align: center;
}
.item_comment_title p {
  font-size: 17px;
}
.item_comment {
  padding-top: 20px;
  text-align: center;
}
.item_comment p {
  text-align: left;
}
.item_comment_btn {
  text-align: center;
}
.item_comment_btn p {
  display: inline-block;
  padding: 11px 17px;
  border: #f29f8f solid 1px;
}
.see_more {
  padding: 10px 16px;
  border: solid 1px #f29f8f;
  margin-top: 20px;
  display: inline-block;
}
.concierges_info_inner {
  display: flex;
}
.concierges_info_inner p {
  font-size: 18px;
  padding-bottom: 20px;
}
.concierge_other {
  width: 100%;
  padding-right: 30px;
}
.concierge_other > * + * {
  margin-left: 20px;
}
.concierge_other a {
  display: inline-block;
}
.concierge_other img {
  width: 60px;
  border-radius: 50%;
}
.concierge_other p {
  font-size: 12px;
  padding: 5px 0 0 0;
  margin: 0;
  text-align: center;
  color: #222;
}
.youtube_description {
  aspect-ratio: 16/9;
}

.blog_description {
  padding-top: 10px;
}

.blog_description a {
  padding-top: 10px;
  font-size: 16px;
  color: #1271b5;
  text-decoration:underline;
  text-decoration-color:#1271b5;
}

</style>